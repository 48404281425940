
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import dayjs from "dayjs";
import { priceRulesEl, verifyMoney } from "@/utils/verify";
import t from "@common/src/i18n/t";
const user = namespace("user");
const coupon = namespace("coupon");
const parts = namespace("parts");
@Component({})
export default class CreatedCoupon extends Vue {
  @user.State userData;
  @user.State selectedOrgCode;
  @coupon.Action orderUnconditional; // 无门槛
  @coupon.Action orderReachedAmount; // 订单满减
  @coupon.Action productUnconditional; //商品无门槛优惠券
  @coupon.Action productReachedAmount; //商品满金额优惠券
  @coupon.Action serviceUnconditional; //服务无门槛优惠券
  @parts.Action getPartsList;
  @coupon.Action queryServiceItemList;
  @coupon.Action getDetail;
  @Watch("couponForm.rewardType")
  couponFormRadio() {
    if (this.couponForm.rewardType === "0401") {
      this.couponForm.radioNumber = "";
    } else {
      this.couponForm.radioNumberYuan = "";
    }
  }
  couponList = [];
  dayjs = dayjs;
  active = 0;
  partsOptions = [];
  couponType = {
    "0101": 0,
    "0102": 1,
    "0201": 2,
    "0202": 3,
    "0301": 4
  };
  project = [];
  fiveBox = [
    { title: t("v210831.no-threshold-coupon"), details: t("v210831.unconditionally-discount-or-discounts") },
    { title: t("v210831.order-full-amount-coupon"), details: t("v210831.discount-or-discount") },
    { title: t("v210831.specified-product-coupons"), details: t("v210831.discount-or-discount-assign") },
    { title: t("v210831.specified-product-full"), details: t("v210831.discount-or-discount-assign-full") },
    { title: t("v210831.specify-project-coupons"), details: t("v210831.discount-or-discount-service-time") }
  ];
  couponForm = {
    activityName: "",
    // time: null, //[new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)]
    totalQuantity: null,
    activityDesc: null,
    rewardType: "0401",
    radioNumber: null,
    radioNumberYuan: undefined,
    activityBegin: undefined,
    activityEnd: undefined,
    limitAmount: undefined,
    slectedParts: [],
    multipleProject: [],
    rewardValue: undefined, // 优惠数值
    productDetails: [],
    activityCode: undefined,
    time: null,
    validPeriod: null,
    validType: "1"
  };
  searchData = {
    pageNum: 1,
    pageSize: 20,
    searchKey: undefined
  };
  loading = false;
  radioNumberRule = (rule, value, callback) => {
    if (!this.couponForm.radioNumberYuan && !this.couponForm.radioNumber) {
      callback(new Error(t("v210831.please-enter-the-conetnt-discounts")));
    } else if (this.couponForm.radioNumberYuan) {
      callback();
    } else if (this.couponForm.radioNumber) {
      let patt = /^(?=0\.[1-9]|[1-9]\.\d).{3}$|^([1-9])$/;
      if (patt.test(value)) {
        callback();
      } else {
        callback(new Error(t("v210831.you-can-enter-0-10-fixed-2")));
      }
    }
  };
  radioNumberRuleYuan = (rule, value, callback) => {
    if (!this.couponForm.radioNumberYuan && !this.couponForm.radioNumber) {
      callback(new Error(t("v210831.please-enter-the-discounts-content")));
    } else if (this.couponForm.radioNumberYuan) {
      if (verifyMoney(value)) {
        callback();
      } else {
        callback(new Error(t("v210831.it-must-be-positive-integer-fixed-2")));
      }
    } else if (this.couponForm.radioNumber) {
      callback();
    }
  };
  callValue(value, callback) {
    if (this.active !== 1 && this.active !== 3) {
      callback();
    } else {
      callback(new Error(t("v210831.please-enter-the-doorsill-amount")));
    }
  }
  doorsillYuan = (rule, value, callback) => {
    if (!value) {
      this.callValue(value, callback);
    } else {
      // priceRulesEl(value, callback);
      if (verifyMoney(value)) {
        callback();
      } else {
        callback(new Error(t("v210831.it-must-be-fixed-2-max")));
      }
    }
  };
  multipleValueVerity(value, callback) {
    if (this.active !== 2 && this.active !== 3) {
      callback();
    } else if (!value || !value.length) {
      callback(new Error(t("setting.select-spare-parts-symbol")));
    } else {
      callback();
    }
  }
  multipleValueArr = (rule, value, callback) => {
    this.multipleValueVerity(value, callback);
  };
  multipleProjectVerity(value, callback) {
    if (this.active !== 4) {
      callback();
    } else if (!value || !value.length) {
      callback(new Error(t("v210801.pls-select-project")));
    } else {
      callback();
    }
  }
  multipleProjectArr = (rule, value, callback) => {
    this.multipleProjectVerity(value, callback);
  };
  multipleTotalQuantity = (rule, value, callback) => {
    if (!value) {
      callback(new Error(t("v210831.please-enter-a-integer")));
    } else {
      this.integerTest(value, callback);
    }
  };
  integerTest(value, callback) {
    let reg = /^[1-9]\d*$/;
    if (reg.test(value)) {
      callback();
    } else {
      callback(new Error(t("v210831.please-enter-a-integer")));
    }
  }

  endTiemVerify = (rule, value, callback) => {
    if (!value) {
      callback(t("v210831.please-select-the-start-time"));
    } else if (this.couponForm.activityBegin > this.couponForm.activityEnd) {
      callback(new Error(t("v210831.please-select-a-reasonable-time")));
    } else {
      callback();
    }
  };
  couponFormRules = {
    activityName: [
      { required: true, message: t("v210831.please-enter-coupon"), trigger: "blur" },
      { min: 1, max: 14, message: t("v210831.the-length-is-1-14"), trigger: "blur" }
    ],
    activityBegin: [{ required: true, validator: this.endTiemVerify, trigger: "change" }],
    activityEnd: [{ required: true, validator: this.endTiemVerify, trigger: "change" }],
    totalQuantity: [{ required: true, validator: this.multipleTotalQuantity, trigger: "blur" }],
    radioNumber: [{ validator: this.radioNumberRule, trigger: "change" }],
    radioNumberYuan: [{ validator: this.radioNumberRuleYuan, trigger: "change" }],
    limitAmount: [{ validator: this.doorsillYuan, trigger: "change" }],
    slectedParts: [{ validator: this.multipleValueArr, trigger: "change" }],
    multipleProject: [{ validator: this.multipleProjectArr, trigger: "change" }],
    // time: { type: "array", required: true, message: t("v210801.please-select-an-activity-time"), trigger: "change" }
  };

  //计算属性
  get breadData() {
    return [
      { name: t("marketing.discount-coupon"), path: "/marketing/discount-coupon" },
      { name: this.$route.params.code ? t("v210831.edit-coupon") : t("marketing.created-coupon") }
    ];
  }
  get filterUserData() {
    return this.userData.storeList.filter(item => {
      return item.orgCode === this.selectedOrgCode;
    });
  }
  created() {
    this.pushAction();
  }
  mounted() {
    this.init();
    if (this.$route.params.code || this.$route.params.copyRow) {
      let copy = (this.$route.params.copyRow as any) || (this.$route.params.code as any);
      this.getDetail({ activityCode: this.$route.params.code || copy }).then(res => {
        const {
          couponType,
          activityName,
          activityBegin,
          activityEnd,
          totalQuantity,
          activityDesc,
          rewardType,
          rewardValue,
          productDetails,
          limitAmount,
          activityCode,
          validPeriod
        } = res.data;
        this.couponForm.time = [
          dayjs(activityBegin).format("YYYYMMDDHHmmss"),
          dayjs(activityEnd).format("YYYYMMDDHHmmss")
        ];
        this.active = this.couponType[couponType];
        this.couponForm.activityName = activityName;
        this.couponForm.totalQuantity = totalQuantity;
        this.couponForm.activityDesc = activityDesc;
        this.couponForm.rewardType = rewardType;
        this.couponForm.limitAmount = limitAmount;
        this.couponForm.validPeriod = validPeriod

        if(validPeriod == -2){
          this.couponForm.validType = '2'
          this.couponForm.activityBegin = activityBegin;
          this.couponForm.activityEnd = activityEnd;
        }else if(validPeriod == -1){
          this.couponForm.validType = '3'
        }

        this.couponForm.activityCode = this.$route.params.copyRow ? null : activityCode; // 空为修改
        if (rewardType === "0401") {
          this.couponForm.radioNumberYuan = rewardValue;
        } else {
          this.couponForm.radioNumber = rewardValue.replace(".", "") / 10;
        }
        if (couponType === "0201" || couponType === "0202") {
          this.couponForm.slectedParts = productDetails.map(item => {
            return {
              label: item.productName,
              value: item.productCode
            };
          });
        } else if (couponType === "0301") {
          this.couponForm.multipleProject = productDetails.map(item => {
            return {
              label: item.productName,
              value: item.productCode
            };
          });
        }
        if (this.$route.params.copyRow) {
          this.couponForm.activityCode = undefined;
        }
        // /////
      });
    }
  }
  pushAction() {
    this.couponList[0] = this.orderUnconditional;
    this.couponList[1] = this.orderReachedAmount;
    this.couponList[2] = this.productUnconditional;
    this.couponList[3] = this.productReachedAmount;
    this.couponList[4] = this.serviceUnconditional;
  }
  init() {
    this.mapPartsList();
    this.mapService();
  }
  mapService() {
    this.queryServiceItemList({ ...this.searchData, pageNum: 1, pageSize: 1000 }).then(res => {
      let mapParts =
        res.data.list &&
        res.data.list.map(item => {
          return {
            label: item.serviceName,
            value: item.serviceCode
          };
        });
      this.project = mapParts;
      this.loading = false;
    });
  }
  mapPartsList() {
    this.getPartsList({ ...this.searchData, pageNum: 1, pageSize: 1000 }).then(res => {
      let mapParts =
        res.data.list &&
        res.data.list.map(item => {
          return {
            label: item.partsName,
            value: item.partsCode
          };
        });
      this.partsOptions = mapParts;
      this.loading = false;
    });
  }
  clickItem(item, index) {
    this.active = index;
    let form = this.$refs.couponForm as any;
    form.resetFields();
  }
  iAmSure(formName) {
    const form = this.$refs[formName] as any;
    form.validate((valid, obj) => {
      if (valid) {
        if(this.couponForm.validType == '2'){
          this.couponForm.activityBegin = this.couponForm.time[0];
          this.couponForm.activityEnd = this.couponForm.time[1];
          if (
            dayjs(this.couponForm.time[1])
              .toDate()
              .getTime() < Date.now()
          ) {
            return this.$message.error(t("v210801.end-time-cannot"));
          }
        }


        if (this.couponForm.radioNumber) {
          let noDot = Number(this.couponForm.radioNumber).toFixed(2);
          noDot = noDot.replace(".", "");
          this.couponForm.rewardValue = (Number(noDot) / 1000).toFixed(2) + "";
        } else {
          this.couponForm.rewardValue = this.couponForm.radioNumberYuan;
        }
        if (this.couponForm.slectedParts.length) {
          this.couponForm.productDetails = this.couponForm.slectedParts.map(item => {
            return {
              productCode: item.value,
              productName: item.label
            };
          });
        } else if (this.couponForm.multipleProject.length) {
          this.couponForm.productDetails = this.couponForm.multipleProject.map(item => {
            return {
              productCode: item.value,
              productName: item.label
            };
          });
        }
        this.requestCoupon();
      } else {
        console.log("error submit!!", obj);
        return false;
      }
    });
  }
  // 请求创建优惠券
  requestCoupon() {

    this.couponList[this.active]({
      ...this.couponForm
    }).then(res => {
      this.$message.success(t("v210831.coupon-saved"));
      this.$router.push("/marketing/discount-coupon");
    });
  }
  remoteMethodParts(query) {
    this.searchData.searchKey = query;
    this.loading = true;
    // 请求后台
    this.mapPartsList();
  }
  remoteMethodService(query) {
    this.searchData.searchKey = query;
    this.loading = true;
    // 请求后台
    this.mapService();
  }
  pickerOptions = {
    disabledDate(time) {
      return time.getTime() < Date.now();
    }
  };
  validTypeChange(val){
    if(val == '1'){
      this.couponForm.activityBegin = undefined
      this.couponForm.activityEnd = undefined
      this.couponForm.validPeriod = val
    }else if(val == '2'){
      this.couponForm.validPeriod = -2
    }else if(val == '3'){
      this.couponForm.activityBegin = undefined
      this.couponForm.activityEnd = undefined
      this.couponForm.validPeriod = -1
    }
  }
  // /////
}
